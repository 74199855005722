import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import Divider from '@theme/components/molecule/Divider'

export default {
  components: {
    AppImage,
    AppTitle,
    Divider,
  },
  data() {
    return {
      lookbooks: null,
    }
  },
  async mounted() {
    const { entities } = await this.$store.dispatch('lookbook/LOAD_LOOKBOOKS', { page: 0, perPage: 3 })
    this.lookbooks = entities
  },
}
